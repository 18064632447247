import type { UseQueryOptions } from 'react-query';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';
import { buildParams } from '../utils/buildParams/buildParams';
import type { SearchSchemaType } from '../schemas/searchSchema';
import type { PreOrdersDto } from '../dtos/PreOrdersDto';
import { PreOrdersDtoSchema } from '../dtos/PreOrdersDto';

type Args = {
  searchParams: {
    endDate: string;
    limit: string;
    operator: SearchSchemaType['operators'];
    page: string;
    startDate: string;
    phone?: string;
  };
  options?: UseQueryOptions<PreOrdersDto>;
};

export const usePreOrdersQuery = ({ searchParams, options }: Args) => {
  const search = buildParams(searchParams);

  return useAuthenticatedQuery(
    {
      schema: PreOrdersDtoSchema,
      url: `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/preorders/search?${search}`,
    },
    { ...options, queryKey: ['preorders', searchParams] }
  );
};
