import { Table } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../../../components/Button';
import Pagination from '../components/Pagination';
import { GoToCell, PhoneNumberCell } from '../components/TicketCells';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import { goToTop, mapOperator } from '../utils';
import RecordsPerPageSelect from '../components/RecordsPerPageSelect';
import { useDecodeSearchParams } from '~/hooks/useDecodeSearchParams';
import { DeliveryCell } from '~/components/Cells/DeliveryCell';
import { PreOrderStatusCell } from '~/components/Cells/PreOrderStatusCell';
import { TicketTypeCell } from '~/components/Cells/TicketTypeCell';
import { usePreOrdersCountQuery } from '~/lib/hooks/usePreOrdersCountQuery';
import { usePreOrdersQuery } from '~/lib/hooks/usePreOrdersQuery';
import type { PreOrdersDto } from '~/lib/dtos/PreOrdersDto';
import { buildParams } from '~/lib/utils/buildParams/buildParams';
import { defaultValues } from '~/hooks/useSearch';
import { DateBreakHandler } from '~/features/customer/FullUserResultsTable';
import { useTranslateColumnNames } from '~/hooks/useTranslateColumnNames';
import type { ColumnsVisibilityDto } from '~/lib/dtos/common/ColumnsVisibilityDto';
import ColumnVisibilityControl from '../components/ColumnVisibilityControl';

const initialColumnVisibility: ColumnsVisibilityDto = {
  DETAILS: true,
  CREATED: true,
  OPERATOR: true,
  PHONE_NUMBER: true,
  DELIVERY_STATUS: true,
  STATUS: true,
  TYPE: true,
};

const PreOrdersTable = () => {
  const [params, originalParams] = useDecodeSearchParams();

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(
    params.preordersPage
      ? Number(params.preordersPage)
      : Number(defaultValues.preordersPage)
  );
  const [itemsPerPage, setItemsPerPage] = useState(
    params.preordersResultsPerPage
      ? Number(params.preordersResultsPerPage)
      : Number(defaultValues.preordersResultsPerPage)
  );

  const navigate = useNavigate();

  const {
    data: preOrders,
    isError,
    isLoading,
  } = usePreOrdersQuery({
    searchParams: {
      startDate: params.startDate!,
      endDate: params.endDate!,
      limit: String(itemsPerPage),
      operator: params.operators ?? [],
      page: String(currentPage),
      phone: params.phoneNumber,
    },
    options: {
      enabled: Boolean(params.endDate && params.startDate),
    },
  });

  // Commented out till backend is ready (DIAL-1214)

  // const preOrdersCount = usePreOrdersCountQuery({
  //   searchParams: {
  //     startDate: params.startDate!,
  //     endDate: params.endDate!,
  //     limit: String(itemsPerPage),
  //     operator: params.operators ?? [],
  //     page: String(currentPage),
  //     phone: params.phoneNumber,
  //   },
  //   options: {
  //     enabled: Boolean(params.endDate && params.startDate),
  //   },
  // });

  useEffect(() => {
    if (!preOrders || !preOrders?.availablePages) {
      return;
    }

    if (preOrders.availablePages <= currentPage - 1) {
      setCurrentPage(preOrders.availablePages);
      return;
    }
  }, [preOrders?.availablePages]);

  useEffect(() => {
    const updatedParams = buildParams({
      ...originalParams,
      preordersPage: currentPage.toString(),
      preordersResultsPerPage: itemsPerPage.toString(),
    });

    navigate('/orders?' + updatedParams);
  }, [currentPage, itemsPerPage]);

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
  };

  const [columnVisibility, setColumnVisibility] =
    useState<ColumnsVisibilityDto>(() => {
      const savedVisibility = localStorage.getItem(
        'PreOrdersTableColumnVisibility'
      );
      return savedVisibility
        ? JSON.parse(savedVisibility)
        : initialColumnVisibility;
    });

  const handleColumnVisibilityChange =
    (column: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedColumnVisibility = {
        ...columnVisibility,
        [column]: e.target.checked,
      };
      setColumnVisibility(updatedColumnVisibility);

      localStorage.setItem(
        'PreOrdersTableColumnVisibility',
        JSON.stringify(updatedColumnVisibility)
      );
    };

  const { translateColumnName } = useTranslateColumnNames();

  return (
    <div className="flex flex-col justify-center px-2 mt-4 overflow-x-auto">
      <div className="flex justify-between">
        <Header className="mt-auto mb-2">
          {t('search.orders-with-hints')}
        </Header>
        <div className="flex">
          <div className="flex flex-col mt-auto">
            <ColumnVisibilityControl
              columnVisibility={columnVisibility}
              handleColumnVisibilityChange={handleColumnVisibilityChange}
              translateColumnName={translateColumnName}
              prefix="preorders"
              excludedColumns={['DETAILS', 'PHONE_NUMBER']}
            />
          </div>
          <RecordsPerPageSelect
            type="lg"
            setItemsPerPage={setItemsPerPage}
            itemsPerPage={itemsPerPage}
          />
        </div>
      </div>

      <Table className="border-spacing-0 mt-2 border border-separate border-gray-300 rounded-md shadow-lg">
        <TableBody
          preOrders={preOrders?.preOrderUsers}
          isError={isError}
          isLoading={isLoading}
          columnVisibility={columnVisibility}
        />
      </Table>
      <Pagination
        onPageChange={handlePageClick}
        pageCount={preOrders?.availablePages ?? 0}
        currentPage={currentPage - 1}
        resultsNumber={preOrders?.count ?? 0}
      />
      {itemsPerPage > 10 && (
        <div className="flex justify-between">
          <Button type="gray" onClick={() => navigate(-1)}>
            {t('common.back')}
          </Button>
          <Button type="gray" onClick={goToTop}>
            {t('common.go-to-top')}
          </Button>
        </div>
      )}
    </div>
  );
};

type TableBodyProps = {
  isLoading: boolean;
  isError: boolean;
  preOrders?: PreOrdersDto['preOrderUsers'];
  columnVisibility: ColumnsVisibilityDto;
};

const TableBody = ({
  preOrders,
  isLoading,
  isError,
  columnVisibility,
}: TableBodyProps) => {
  const { t } = useTranslation();

  const { translateColumnName } = useTranslateColumnNames();

  if (isError) {
    return (
      <div className="flex justify-center">
        <Table.Head>
          <Table.HeadCell>{t('status.error')}</Table.HeadCell>
        </Table.Head>
      </div>
    );
  }
  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Table.Head>
          <Table.HeadCell>{t('common.loading')}</Table.HeadCell>
        </Table.Head>
      </div>
    );
  }
  if (!preOrders) {
    return (
      <div className="flex justify-center">
        <Table.Head>
          <Table.HeadCell>
            {t('common.no-orders-for-this-search-criteria')}
          </Table.HeadCell>
        </Table.Head>
      </div>
    );
  }
  if (preOrders.length === 0) {
    return (
      <div className="flex justify-center">
        <Table.Head>
          <Table.HeadCell>
            {t('common.no-orders-for-this-search-criteria')}
          </Table.HeadCell>
        </Table.Head>
      </div>
    );
  }
  return (
    <>
      <Table.Head className="bg-gray-100">
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {translateColumnName('DETAILS')}
          </div>
        </Table.HeadCell>
        {columnVisibility.CREATED && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('CREATED')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.OPERATOR && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('OPERATOR')}
            </div>
          </Table.HeadCell>
        )}
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {translateColumnName('PHONE_NUMBER')}
          </div>
        </Table.HeadCell>
        {columnVisibility.DELIVERY_STATUS && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('DELIVERY_STATUS')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.STATUS && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('STATUS')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.TYPE && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('TYPE')}
            </div>
          </Table.HeadCell>
        )}
      </Table.Head>
      <Table.Body className="divide-y">
        {preOrders.map(({ preOrder, user, deliveryNotification }, id) => {
          return (
            <Table.Row
              key={id}
              className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white"
            >
              <GoToCell type="order" id={String(preOrder.id.value)} />
              {columnVisibility.CREATED && (
                <Table.Cell className="text-center border-2 border-gray-100">
                  <DateBreakHandler date={preOrder.createdAt} />
                </Table.Cell>
              )}
              {columnVisibility.OPERATOR && (
                <Table.Cell className="text-center border-2 border-gray-100">
                  {mapOperator(preOrder.operator)}
                </Table.Cell>
              )}
              <PhoneNumberCell
                className="whitespace-nowrap text-center border-2 border-gray-100"
                phoneNumber={user.phoneNumber.value}
                customerId={user.id.value}
              />
              {columnVisibility.DELIVERY_STATUS && (
                <DeliveryCell
                  fromDate={preOrder.createdAt}
                  toDate={deliveryNotification?.timestamp ?? null}
                  status={deliveryNotification?.status ?? null}
                />
              )}
              {columnVisibility.STATUS && (
                <PreOrderStatusCell preOrderStatus={preOrder.status} />
              )}
              {columnVisibility.TYPE && (
                <TicketTypeCell
                  className="text-center border-2 border-gray-100"
                  type={preOrder.ticketType}
                  distance={preOrder.distance.value}
                />
              )}
            </Table.Row>
          );
        })}
      </Table.Body>
    </>
  );
};

export default PreOrdersTable;
