import type { UseQueryOptions } from 'react-query';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';
import { buildParams } from '../utils/buildParams/buildParams';
import type { SearchSchemaType } from '../schemas/searchSchema';
import type { OrdersDto } from '../dtos/OrdersDto';
import { OrdersDtoSchema } from '../dtos/OrdersDto';

type Args = {
  searchParams: {
    endDate: string;
    limit: string;
    operator: SearchSchemaType['operators'];
    page: string;
    startDate: string;
    phone?: string;
  };
  options?: UseQueryOptions<OrdersDto>;
};

export const useOrdersQuery = ({ searchParams, options }: Args) => {
  const search = buildParams(searchParams);

  return useAuthenticatedQuery(
    {
      schema: OrdersDtoSchema,
      url: `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/orders/search?${search}`,
    },
    { ...options, queryKey: ['orders', searchParams] }
  );
};
