import clsx from 'clsx';
import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import type { IncomingMessageTypeDto } from '~/lib/dtos/common/IncomingMessageTypeDto';
import type { OutGoingMessageTypeDto } from '~/lib/dtos/common/OutgoingMessageTypeDto';

interface MessageTypeCellProps {
  type: IncomingMessageTypeDto | OutGoingMessageTypeDto;
  className?: string;
}

export const MessageTypeCell = ({ type, className }: MessageTypeCellProps) => {
  const { t } = useTranslation();

  const messageTypeMap: Record<
    IncomingMessageTypeDto | OutGoingMessageTypeDto,
    string
  > = {
    NEW_ORDER: t('incoming-message-status.new-order'),
    CONFIRMATION: t('incoming-message-status.confirmation'),
    CANCEL: t('incoming-message-status.cancel'),
    HINTS: t('incoming-message-status.hints'),
    DELAYED: t('incoming-message-status.delayed'),
    ORDER_RECEIPT: t('incoming-message-status.order-receipt'),
    GET_CONFIRMATION: t('outgoing-message-status.get-confirmation'),
    FIRST_WARNING: t('outgoing-message-status.first-warning'),
    TICKET: t('outgoing-message-status.ticket'),
    WELCOME: t('outgoing-message-status.welcome'),
    ERROR: t('outgoing-message-status.error'),
    PERMANENTLY_BLOCKED: t('outgoing-message-status.permanently-blocked'),
    TEMPORARILY_BLOCKED: t('outgoing-message-status.temporarily-blocked'),
    TICKET_REFUNDED: t('outgoing-message-status.payment-refunded'),
    ALREADY_PROCESSING: t('outgoing-message-status.already-processing'),
    ERROR_PARSING: t('outgoing-message-status.error-parsing'),
    ONE_ZONE_ERROR: t('outgoing-message-status.one-zone-error'),
    WRONG_DISTANCE: t('outgoing-message-status.wrong-distance'),
    INVALID_ZONES: t('outgoing-message-status.invalid-zones'),
    WRONG_START_ZONE: t('outgoing-message-status.wrong-start-zone'),
    CREATING_TEMPORARY_BLOCK: t(
      'outgoing-message-status.creating-temporary-block'
    ),
    CREATING_PERMANENT_BLOCK: t(
      'outgoing-message-status.creating-permanent-block'
    ),
    UNBLOCK: t('outgoing-message-status.unblock'),
    WRONG_CONFIRMATION_TRY_AGAIN: t(
      'outgoing-message-status.wrong-confirmation-try-again'
    ),
    WARNING_1_MESSAGE: t('outgoing-message-status.warning-1-message'),
    WARNING_2_MESSAGE: t('outgoing-message-status.warning-2-message'),
    WARNING_3_MESSAGE: t('outgoing-message-status.warning-3-message'),
    WARNING_4_MESSAGE: t('outgoing-message-status.warning-4-message'),
    WARNING_5_MESSAGE: t('outgoing-message-status.warning-5-message'),
    WARNING_6_MESSAGE: t('outgoing-message-status.warning-6-message'),
    DELAYED_MESSAGE: t('outgoing-message-status.delayed-message'),
    TEMPORARILY_BLOCKED_MANUALLY: t(
      'outgoing-message-status.temporarily-blocked-manually'
    ),
    PERMANENTLY_BLOCKED_MANUALLY: t(
      'outgoing-message-status.permanently-blocked-manually'
    ),
    TEMPORARILY_BLOCKED_AUTOMATICALLY: t(
      'outgoing-message-status.temporarily-blocked-automatically'
    ),
    PERMANENTLY_BLOCKED_AUTOMATICALLY: t(
      'outgoing-message-status.permanently-blocked-automatically'
    ),
    RECEIPT_ORDERED: t('outgoing-message-status.receipt-ordered'),
    NO_TICKETS_FOR_RECEIPT: t('outgoing-message-status.no-tickets-for-receipt'),
    INVALID_RECEIPT_ORDER: t('outgoing-message-status.invalid-receipt-order'),
    GENERIC_BILLING_ERROR: t('outgoing-message-status.generic-billing-error'),
  };

  const returnMessageType = (
    type: IncomingMessageTypeDto | OutGoingMessageTypeDto
  ) => {
    return messageTypeMap[type] || type;
  };

  return (
    <Table.Cell className={clsx('px-3 py-1.5', className)}>
      {returnMessageType(type)}
    </Table.Cell>
  );
};
